import React, { useState } from 'react';
import { Flex, Button, Heading } from '@theme-ui/components';
import Link from 'gatsby-link';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons';
import CloudinaryImage from '../../../../../../gatsby-theme-minimal/src/components/General/CloudinaryImage';
import shortid from 'shortid';
import slugifyLower from '../../../../../../gatsby-theme-minimal/src/helpers/slugifyLower';
import useCurrentLocationNameAndSlug from '../../../../../../gatsby-theme-minimal/src/hooks/useCurrentLocationNameAndSlug';
import useLocationNamesAndSlugs from '../../../../../../gatsby-theme-minimal/src/hooks/useLocationNamesAndSlugs';

export default function LocationSwitcherV1({
  businessData,
  locations,
  variantName,
  buttonName,
  // locationIndex,
  // ...rest
}) {
  const [toggle, setToggle] = useState(false);

  const variant = variantName ? variantName : 'locationSwitcherV1';

  const { locationNames, locationSlugs } = useLocationNamesAndSlugs();
  const {
    currentLocationName,
    // currentLocationSlug,
  } = useCurrentLocationNameAndSlug();

  return (
    <Flex
      variant={`${variant}.container`}
      className="locationSwitcherV1 container"
    >
      <Button
        variant={`${variant}.button`}
        sx={{
          padding: '.75rem 2rem!important',
          color: 'white',
        }}
        onClick={() => setToggle(!toggle)}
        className="button"
      >
        Change Location
      </Button>
      {toggle ? (
        <Flex variant={`${variant}.popUp`} className="popUp">
          <Flex
            variant={`${variant}.popUpBackground`}
            className="popUpBackground"
            onClick={() => setToggle(!toggle)}
          />
          <Flex variant={`${variant}.content`} className="content">
            <CloudinaryImage
              variant={`${variantName}.logo`}
              className="logo"
              imageBaseUrl={businessData.avatar.imageBaseUrl}
              imagePrefix={businessData.avatar.imagePrefix}
            />
            <Heading variant={`${variant}.title`} className="title">
              Our Locations
            </Heading>
            <Flex variant={`${variant}.locations`} className="locations">
              <Link key={shortid.generate()} to={`/`}>
                Homepage
              </Link>
              {locationNames.map((location, index) => {
                console.log(locationSlugs[index]);
                return (
                  <Link
                    className={`${slugifyLower(locationSlugs[index])}`}
                    key={shortid.generate()}
                    to={
                      locationSlugs[index] === 'bridgeport'
                        ? 'https://massimosristorante.netlify.app/'
                        : `/${slugifyLower(locationSlugs[index])}`
                    }
                  >
                    {location.toUpperCase()}
                  </Link>
                );
              })}
            </Flex>
          </Flex>
        </Flex>
      ) : (
        ''
      )}
    </Flex>
  );
}
